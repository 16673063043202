import React, { FC } from "react";
import { Button, Icon } from "../../modules";

export const GeneralPopupCompnt: FC<any> = (props) => {
  return (
    <div className="general-popup-compnt">
      <div className="popup-container">
        <div className="popup-wraper">
          <div className="btnClose" onClick={() => props?.onClose()}>
            <Icon.ClosePopup />
          </div>
          <div className="popup-body">
            <img className="pic-confirm" src="/assets/images/popup-confirm-pic.png" alt="" />
            {props.titlePopup ? <div className="title-popup"> {props.titlePopup} </div> : null}
            <div className="message-popup">{props.messagePopup}</div>
            <div className="button-container">
              <Button
                label={"Confirm"}
                type="submit"
                buttonType="info"
                className="confirm-button"
                onClick={() => props?.onClickConfirm()}
                isLoading={props.isLoading}
                disabled={props.isLoading}
              />
              <Button
                label={"Cancel"}
                type="button"
                buttonType="grey"
                className="cancel-button"
                onClick={() => props?.onClose()}
                disabled={props.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
