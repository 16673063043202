import React, { useState } from "react";
import { Icon, InputSelect } from "../../../components";
import { TableFilterInputText } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, ITableStructureItem, Table } from "../../../modules";
import { withStartupWraper } from "../wrapper";
import moment from "moment";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { translate } from "../../../languages";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { StartupService } from "../../../services/startup";
import { PopupWraper } from "../../../components/popup";
import XLSX from 'xlsx';


export const StartupManagement = withStartupWraper(
  () => {
    const [params, setParams] = useState({});
    const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
    // const [openConfirmPopup, setOpenConfirmPopup] = useState<any>();

    const [disableExport, setDisableExport] = useState<boolean>(true);

    const [dataItemChangeStatus, setDataItemChangeStatus] = useState<any>();
    const [valueChangeStatus, setValueChangeStatus] = useState<any>(null);
    const [isRequesting, setIsRequesting] = useState<boolean>(false);

    // const onChangeActiveStatus = (param: any) => {
    //   return StartupService.updateStartupActiveStatus(param).then(() => {
    //     CreateAlert({message: "Reject startup successfully", type: "success"});
    //     setForceUpdateTable(Math.random());
    //     setOpenConfirmPopup("");

    //   }).catch((err: any) => {
    //     const error = {...err}
    //     CreateAlert({message: error.message, type: "danger"});
    //   })
    // }

    const TypeAgencyList = [
      {
        label: "Agency",
        value: 1,
      },
      {
        label: "Startup",
        value: 2,
      }
    ]

    const approvalStatusList = [
      {
        label: translate("JUST_CREATED"),
        value: 0,
      },
      {
        label: translate("APPROVED"),
        value: 1,
      },
      {
        label: translate("CANCELLED"),
        value: 2,
      },
      {
        label: translate("PENDING"),
        value: 3,
      },
    ];

    const activeStatusOptions = [
      {
        label: translate("ACTIVATED"),
        value: 0,
      },
      {
        label: translate("DEACTIVATED"),
        value: 1,
      },
      {
        label: translate("NOT_SHOWED"),
        value: 2,
      },
      
    ]

    const structure: ITableStructureItem[] = [
      {
        name: "Project name",
        key: "projectName",
        render: (item) => !!item.projectName ? item.projectName : "--"
      },
      {
        name: "Representative email",
        key: "representativeEmail",
        // render: (item) => {
        //   return <span className="email">{item.email}</span>;
        // },
      },
      {
        name: "Created date",
        key: "created",
        render: (item) => {
          return (
            <span>
              {moment(item?.created).format('DD/MM/y HH:mm:ss')}
            </span>
          );
        },
      },
      {
        name: "Approval",
        key: "approvalStatus",
        render: (item) => (
          <span 
            className={`status status--${item?.approvalStatus === 0 ? "just_created" : item?.approvalStatus === 1 ? "approved" : item?.approvalStatus === 2 ? "cancel" : "pending"}`}
          >
            {item?.approvalStatus === 0 
              ? translate("JUST_CREATED")
              :  item?.approvalStatus === 1 
              ? translate("APPROVED")
              : item?.approvalStatus === 2 
              ? translate("CANCELLED")
              : translate("PENDING") 
            }
          </span>
        ),
      },
     
      {
        name: translate("status"),
        key: "activeStatus",
        render: (item) => (
          <span 
            className={`status status--${item?.activeStatus === 0 ? "activated" : item?.activeStatus === 1 ? "deactived" : "not_showed"}`}
          >
            {item?.activeStatus === 0 ? translate("ACTIVATED") : item?.activeStatus === 1 ? translate("DEACTIVATED") : translate("NOT_SHOWED") }
          </span>
        ),
      },
      {
        name: translate("actions"),
        key: "actions",
        render: (item) => {
          return (
            <div className="actions">
              {item.activeStatus !== 2 && (
                <div className="action__icon">
                  <NavLink to={Routes.startupDetail.renderPath(item.agencyId)} exact={true}>
                    <Icon.ActionEditIcon />
                  </NavLink>
                </div>
              )}
              <div className="action__icon" onClick={() => {
                setValueChangeStatus(activeStatusOptions?.find(x => x.value === item?.activeStatus)); 
                setDataItemChangeStatus(item);
              }}>
                <Icon.ActionMoreIcon />
              </div>
            </div>
          );
        },
      },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await StartupService.getStarupList({
            ...params,
            page: 1,
            pageSize: 10000,
          });

          const data = response.data;

          let fileHead:any = structure.map((v) => v.name);
          fileHead.pop(); //remove last column on table
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (column.key === "created") return moment(item[column.key]).format('DD/MM/y HH:mm:ss');
                if (column.key === "projectName") return item[column.key] || "--";
                if (column.key === "approvalStatus") 
                  return item?.approvalStatus === 0 
                    ? translate("JUST_CREATED")
                    :  item?.approvalStatus === 1 
                    ? translate("APPROVED")
                    : item?.approvalStatus === 2 
                    ? translate("CANCELLED")
                    : translate("PENDING") ;
                if (column.key === "activeStatus") return item?.activeStatus === 1 ? translate("DEACTIVATED") : translate("NOT_SHOWED")
                

                if (!column.key) return "";
                return item[column.key];
              })
            ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(
            wb,
            `Startup List ${now.toLocaleDateString().replace(/\//g, "-")} ${now
              .toLocaleTimeString()
              .replace(/:/g, "-")}.xlsx`,
            { type: "binary" }
          );

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error:any) {
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    const handleOnClickConfirmChangeStatus = (item: any) => {
      let payload = {
        agencyId: item?.agencyId,
        activeStatus: valueChangeStatus?.value,
      }
      StartupService.updateStartupActiveStatus(payload)
        .then((res: any) => {
          setForceUpdateTable(Math.random());
          CreateAlert({ message: `Change successfully.`, type: "success" });
        })
        .catch((err: any) => {
          CreateAlert({ message: translate(err?.message), type: "danger" });
        })
        .finally(() => {
          setDataItemChangeStatus(null);
          setValueChangeStatus(null);
        });
    }

    return (
      <div className="StartupManagement">
        <Button
          className="mb20"
          label="Export to Excel"
          buttonType="primary"
          disabled={disableExport}
          onClick={handleExportExcel}
        />

        <Table
          hasOrderColumn
          filters={[
            {
              name: "Search",
              key: "searchString",
              // input: TableFilterInputText,
              input: (s) => (
                <TableFilterInputText {...s} placeholder="Search project name, email"/>
              ),
            },
            {
              name: "Project type",
              key: "type",
              input: (s) => (
                <TableFilterInputSelect
                  {...s}
                  options={TypeAgencyList}
                  isClearable={true}
                  isSearchable={false}
                />
              ),
            },
            {
              name: "Approval Status",
              key: "approvalStatus",
              input: (s) => (
                <TableFilterInputSelect
                  {...s}
                  options={approvalStatusList}
                  isClearable={true}
                  isSearchable={false}
                />
              ),
            },
            {
              name: translate("status"),
              key: "activeStatus",
              input: (s) => (
                <TableFilterInputSelect
                  {...s}
                  options={activeStatusOptions}
                  isClearable={true}
                  isSearchable={false}
                />
              ),
            },
          ]}
          structure={structure}
          isOpenFilterBox
          fetchData={async (params) => {
            setParams(params);
            return StartupService.getStarupList({
              ...params,
              page: params.pageNumber,
              pageSize: params.limit,
            }).then((res) => {
              setDisableExport(!(res?.data?.length > 0));
              return res;
            });
          }}
          forceUpdateTable={forceUpdateTable}
        />

        {dataItemChangeStatus && (
          <PopupWraper center title="Change Status" onClose={() => setDataItemChangeStatus(null)}>
            <div className="mb8">Change Active Status For "<strong>{dataItemChangeStatus?.name}</strong>"</div>
            <InputSelect
              name=""
              onTouched={() => null}
              options={activeStatusOptions}
              defaultValue={activeStatusOptions?.find(x => x.value === dataItemChangeStatus?.activeStatus)}
              value={valueChangeStatus}
              onChange={e => setValueChangeStatus(activeStatusOptions?.find(x => x.value === e))}
              isClearable={false}
            />
            <div className="d-flex justify-content-center mt24">
              <Button 
                label={"Save"}
                onClick={() => handleOnClickConfirmChangeStatus(dataItemChangeStatus)}
                isLoading={isRequesting}
                disabled={isRequesting}
              />
            </div>
          </PopupWraper>
        )}
      </div>
    );
  })
